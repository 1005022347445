import React, { useEffect, useState } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Navbar from "react-bootstrap/Navbar";
import Pagination from "react-bootstrap/Pagination";
import Spinner from "react-bootstrap/Spinner";
import ScrollUpButton from "react-scroll-up-button";
import { Link, useLocation } from "react-router-dom";
import { LandingPageFooter } from "landing-page-footer";
import styles from "../css/search.module.css";
import "../App.css";

const SearchIndex = () => {
  const location = useLocation()
  
  const [filterToggle, setFilterToggle] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [listingData, setListingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredListingData, setFilteredListingData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [displayMin, setDisplayMin] = useState(0);
  const [displayMax, setDisplayMax] = useState(11);
  const [navbar, setNavbar] = useState(false);
  const numberOfPages = Math.ceil(filteredListingData.length / 12);
  const today = new Date();
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const handleSearchScroll = () => {
    const searchBar = document.getElementById("searchBar");
    searchBar.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const handleMinMax = (pageNumber) => {
    const makeMin = 12 * pageNumber - 12;
    const makeMax = 12 * pageNumber - 1;
    setDisplayMin(makeMin);
    setDisplayMax(makeMax);
  };

  const pagePrev = () => {
    if (pageNumber > 1) {
      setPageNumber((prev) => {
        const newPage = --prev;
        handleMinMax(newPage);
        return newPage;
      });
      handleSearchScroll();
    }
  };

  const pageNext = () => {
    if (pageNumber < numberOfPages) {
      setPageNumber((prev) => {
        const newPage = ++prev;
        handleMinMax(newPage);
        return newPage;
      });
      handleSearchScroll();
    }
  };

  const handleListingSearch = (searchValue) => {
    setSearchText(searchValue);
    const listingDataCopy = [...listingData];
    const formatSearchText = searchValue?.toString().toLowerCase();
    const checkItem = (item) =>
      item?.toString().toLowerCase().includes(formatSearchText);
    const filteredData = listingDataCopy.filter(
      (item) =>
        checkItem(item?.city) ||
        checkItem(item?.state) ||
        checkItem(item?.zip) ||
        checkItem(item?.address)
    );
    handleMinMax(1);
    setPageNumber(1);
    setFilteredListingData(filteredData);
  };
  const handleFilterToggle = () => {
    setFilterToggle((prev) => !prev);
    setFilteredListingData((prev) => [...prev].reverse());
  };

  const handlePagination = (e) => {
    const newPage = +e.target.innerText;
    if (!isNaN(+newPage)) {
      handleMinMax(newPage);
      setPageNumber(newPage);
      handleSearchScroll();
    }
  };

  useEffect(() => {
    //logo scroll when active
    const changeBackground = () => {
      if (window.scrollY >= 66) {
        setNavbar(true);
      } else {
        setNavbar(false);
      }
    };
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);

    return () => window.removeEventListener("scroll", changeBackground);
  });

  useEffect(() => {
    const getData = async () => {
      const startTime = new Date();
      setLoading(true);
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_CDN_BUCKET_URL}/open-house/active-listings/active-listings.json`
        );
        const filteredData = data.filter((item) => item.loan !== "");

        if (filteredData) {
          setFilteredListingData(filteredData);
          setListingData(filteredData);
        }
      } catch (err) {
        console.log(err);
      } finally {
        const endTime = new Date();
        const timeElapsed = startTime.getTime() - endTime.getTime();
        if (timeElapsed < 1000) {
          setTimeout(() => {
            setLoading(false);
          }, 1000 - timeElapsed);
        } else {
          setLoading(false);
        }
      }
    };
    getData();
  }, []);

  return (
    <article className={styles.site}>
      {loading ? (
        <div className="w-100 d-flex justify-content-center align-items-start">
          <Spinner />
        </div>
      ) : (
        <React.Fragment>
          <div className={styles.banner}>
            <Navbar
              expand="lg"
              fixed="top"
              bg="dark"
              variant="dark"
              className={navbar ? "navbar active" : "navbar"}
            >
              <Container>
                <Navbar.Brand
                  href="https://www.swmc.com"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="a-link"
                >
                  <img
                    className={styles.logo}
                    src={`${process.env.REACT_APP_CDN_BUCKET_URL}/property-listings/img/swmc-logo.png`}
                    alt="swmc logo"
                  />
                </Navbar.Brand>
              </Container>
            </Navbar>
            <div className={styles.banner_title}>Find a home you'll love</div>
          </div>
          <Container>
            <Row className="d-flex flex-column flex-md-row justify-content-center align-items-center w-100 m-0 mb-5 p-0">
              <Col>
                <div></div>
              </Col>
              <Col className="p-0" md={6}>
                <div id="searchBar" className={styles.search_box}>
                  <div className={styles.listings_number}>
                    {loading ? (
                      <Spinner />
                    ) : (
                      `${filteredListingData.length} Agent Listings`
                    )}
                  </div>
                  <div className={styles.search_input_box}>
                    <input
                      className={styles.search_input}
                      type="text"
                      placeholder="Search"
                      value={searchText}
                      onChange={(e) => handleListingSearch(e.target.value)}
                    />

                    <img
                      src={`${process.env.REACT_APP_CDN_BUCKET_URL}/property-listings/img/magnifying-glass.svg`}
                      alt="search"
                      className={styles.search_icon}
                    />
                  </div>
                </div>
              </Col>
              <Col
                className={`${styles.sort_border} d-flex justify-content-center justify-content-md-start py-2`}
              >
                <div className={`${styles.sort_toggle_box}`}>
                  <input
                    type="checkbox"
                    checked={filterToggle}
                    className={styles.sort_toggle}
                    onChange={handleFilterToggle}
                  />
                  <span onClick={handleFilterToggle} className={styles.arrow}>
                    {">"}
                  </span>
                </div>
              </Col>
            </Row>
            <Row className={styles.listing_box}>
              {filteredListingData.length > 0 ? (
                filteredListingData.map((listing, i) => {
                  const segments = listing.landingPageName.split("/");
                  const listingPageName = segments[segments.length - 1];
                  console.log(listingPageName);
                  const listDate = new Date(1970, 0, 1);
                  listDate.setSeconds(listing.listingDate);
                  const oneDay = 24 * 60 * 60 * 1000;
                  const diffDays = Math.round(
                    Math.abs((today - listDate) / oneDay)
                  );
                  const diffMonths = Math.round(Math.abs(diffDays / 30));
                  const dateText =
                    diffDays > 29
                      ? `${diffMonths} Month${diffMonths > 1 ? "s" : ""} Ago`
                      : `${diffDays} Days Ago`;
                  if (displayMin <= i && i <= displayMax) {
                    return (
                      <Col
                        key={listing.listingId}
                        className={styles.card}
                        md={6}
                        lg={4}
                      >
                        <Link
                          to={
                            listingPageName
                              ? '/' + listingPageName
                              : listing.landingPageName
                          }
                          className={styles.link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div className={styles.listing_date}>{dateText}</div>
                          <Image
                            className={styles.card_image}
                            src={`${process.env.REACT_APP_LISTING_THUMBNAIL_CDN_URL}${listing.listingId}.jpg`}
                            alt="Thumbnail Image"
                          />
                          <div className={styles.card_content_box}>
                            <div className={styles.offer_price}>
                              {currencyFormatter.format(
                                parseInt(listing.offerPrice)
                              )}
                            </div>
                            <div
                              className={styles.rooms}
                            >{`${listing.bedroomUnit} bds | ${listing.bathroomUnit} ba | ${listing.livableInterioArea}sqft - For Sale as per standard`}</div>
                            <div
                              className={styles.address}
                            >{`${listing?.address}, ${listing?.city}, ${listing?.state}, ${listing?.zip}`}</div>
                          </div>
                        </Link>
                      </Col>
                    );
                  }
                })
              ) : (
                <div className={`${styles.no_match} mb-5`}>
                  No Listing Matches The Given Search
                </div>
              )}
            </Row>

            <Pagination
              className={`${styles.page} d-flex justify-content-center`}
              size={"sm"}
            >
              <Pagination.Prev
                className={`${styles.page_item} ${styles.page_arrow}`}
                onClick={pagePrev}
              />
              {Array.from(Array(numberOfPages).keys()).map((page) => {
                return (
                  <Pagination.Item
                    className={styles.page_item}
                    onClick={(e) => handlePagination(e)}
                    active={+pageNumber === +page + 1}
                    key={page}
                  >
                    {page + 1}
                  </Pagination.Item>
                );
              })}
              <Pagination.Next
                className={`${styles.page_item} ${styles.page_arrow}`}
                onClick={pageNext}
              />
            </Pagination>
          </Container>
        </React.Fragment>
      )}
      <LandingPageFooter />
      <ScrollUpButton
        style={{
          width: 60,
          height: 60,
          bottom: "70px",
          borderRadius: "30px",
          background: "#0054a6",
        }}
        ContainerClassName="scrollToTop"
        TransitionClassName="overRideTransition"
      />
    </article>
  );
};

export default SearchIndex;
