import { useState, useEffect, Suspense, lazy } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import ScrollUpButton from "react-scroll-up-button";
import Helmet from "react-helmet";
import axios from "axios";
import "../css/ListingInfo.css";
import "../css/TruApprovalContactForm.css";
import SpinnerLoader from "../spinners/SpinnerLoader";
import ErrorPage from "../error/ErrorPage";
import { LandingPageFooter } from "landing-page-footer";
import "@fontsource/poppins";

const StaffInfo = lazy(() => import("../staff/StaffInfo"));
const PropertyInfo = lazy(() => import("../property/PropertyInfo"));
const LoanScenario = lazy(() => import("../scenario/LoanScenario"));
const Disclaimer = lazy(() => import("../disclaimer/Disclaimer"));
const utils = require("../utils/utils");

function ListingInfo() {
  //constant variables
  const LISTING_ID_KWD = "{listingId}";
  const JSON_PARSE_ERROR_EMAIL_SUBJECT_LINE =
    "[Urgent] Property Listing Id - " + LISTING_ID_KWD + " JSON Error";
  //declare variables
  
  let originalListingId = "";
  let listingDate = "";
  let loanOfficerData = "";
  let loanOfficerDisclaimer = "";
  let realtorData = "";
  let realtorDisclaimer = "";
  let propertyData = "";
  let loanScenarioData = "";
  let buyDown = false;
  let buyDownScenarioData = "";
  let disclaimerData = "";
  let propertySoldData = "";
 
  //get listing id details from url params
  const { listingId } = useParams();
  const propertyListingURL = useState(window.location.href);
  const [siteMetaData, setSiteMetaData] = useState({});
  //declare constant variables
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [showErrorPage, setShowErrorPage] = useState(false);
  const [loanOfficerIsActive, setLoanOfficerIsActive] = useState(false);
  const [listingJSONData, setListingJSONData] = useState({});
  const [truApprovalContactTeamInfo, setTruApprovalContactTeamInfo] = useState(
    {}
  );

  /**
   * @author Pratik Kajrolkar
   * @date 04/29/2022
   *
   * The below function is used to check if loan officer is active.
   */
  const checkIfLoanOfficerIsActive = async () => {
    try {
      if (listingJSONData.length !== undefined) {
        const listingInfoData = JSON.parse(listingJSONData);
        const loanOfficerUID = listingInfoData.staff.loanOfficer.loSSID;
        const params = {
          method: process.env.REACT_APP_CHECK_LOF_ACTIVE,
          loanOfficerId: loanOfficerUID,
        };

        const response = await axios.post(
          process.env.REACT_APP_AWS_S3_SERVICE,
          params
        );
        if (response.data) {
          setLoanOfficerIsActive(response.data.loanOfficerActive);
          if (!response.data.loanOfficerActive) {
            await loadTruApprovalContactTeamInfo();
          }
        }
      }
    } catch (e) {
      console.debug("Unable to check if loan officer is active: " + e);
      await loadTruApprovalContactTeamInfo();
    }
  };
  const getActiveListingJson = async () => {
    try {
      const data = await axios.get(
        process.env.REACT_APP_CDN_BUCKET_URL + "open-house/active-listings/active-listings.json"
      );
      console.log(data);
      
      return data;
    } catch (err) {
      console.log(err);
      return null;
    }
  };
  /**
   * @author Pratik Kajrolkar
   * @date 05/02/2022
   *
   * The below function is will load tru approval contact team information
   */
  const loadTruApprovalContactTeamInfo = async () => {
    try {
      const params = {
        method: process.env.REACT_APP_GET_TRU_APPROVAL_CONTACT_TEAM_INFO,
      };

      const response = await axios.post(
        process.env.REACT_APP_AWS_S3_SERVICE,
        params
      );
      if (response.data) {
        setTruApprovalContactTeamInfo(response.data.data);
      }
    } catch (e) {
      console.debug(
        "Unable to load tru approval contact team information: " + e
      );
    }
  };

  useEffect(() => {
    /**
     * @author Pratik Kajrolkar
     * @date 01/08/2021
     *
     * The below function is used to fetch the property listing JSON from the
     * AWS S3 bucket location.
     * @param {String} objectName the json object which will fetch from S3 bucket
     * @returns JSON Object of the requested file
     */
    const fetchPropertyListingJSONFromS3Bucket = async (objectName) => {
      try {
        let folderPath = process.env.REACT_APP_JSON_S3_BUCKET_FOLDER_PATH;

        const params = {
          method: process.env.REACT_APP_GET_JSON_OBJECT,
          folderPath: folderPath,
          objectName: objectName,
        };

        await axios
          .post(process.env.REACT_APP_AWS_S3_SERVICE, params)
          .then((response) => {
            setListingJSONData(response.data.data);
            console.log(JSON.parse(response.data.data));
            setLoadingComplete(true);
          })
          .catch((error) => {
            console.log(error);
            setShowErrorPage(true);
          });

        await checkIfLoanOfficerIsActive();
       
      } catch (e) {
        console.log(e);
        setShowErrorPage(true);
      }
    };

    const objectName = listingId + utils.JSON_EXTENSTION;
    fetchPropertyListingJSONFromS3Bucket(objectName);
  }, [listingJSONData]);

  if (showErrorPage) {
    return <ErrorPage />;
  }

  if (listingJSONData.length !== undefined) {
    try {
     const listingData = JSON.parse(listingJSONData);
      originalListingId = listingData.listingId;
      listingDate = listingData.listingDate;
      //load loan officer data from JSON
      try {
        loanOfficerData = listingData.staff.loanOfficer;
        if (loanOfficerIsActive) {
          loanOfficerDisclaimer = loanOfficerData.disclaimer;
        }
      } catch (e) {
        console.debug("Loan Officer details are not present: " + e);
      }

      //load realtor data from JSON
      try {
        realtorData = listingData.staff.realtor;
        realtorDisclaimer = listingData.staff.realtor.disclaimer;
      } catch (e) {
        console.debug("Realtor details are not present: " + e);
      }

      //load property data from JSON
      try {
        propertyData = listingData.property;
      } catch (e) {
        console.debug("Property details are not present: " + e);
      }

      //load loan scenarios data from JSON
      try {
        loanScenarioData = listingData.loanScenario;
      } catch (e) {
        console.debug("Loan Scenario(s) details are not present: " + e);
      }

      //load disclaimer data from JSON
      try {
        disclaimerData = listingData.disclaimers;
      } catch (e) {
        console.debug("Disclaimers details are not present: " + e);
      }

      //load property sold data from JSON
      try {
        propertySoldData = listingData.propertySold;
      } catch (e) {
        console.debug("Property Sold details are not present: " + e);
      }

      //load buydown scenario data from JSON
      try {
        buyDown = listingData.buydown;
        buyDownScenarioData = listingData.buydownScenario;
      } catch (e) {
        console.debug("Buydown scenario details is not present: " + e);
      }
    } catch (e) {
      console.log("Unable to parse JSON: " + e);
      const errorEmailMsgBody =
        "Hi Team,\n\n" +
        "Users are unable to view property listing page " +
        propertyListingURL[0] +
        "due to JSON parsing error: \n\n[" +
        e +
        "].\n\nPlease verify the content of the properties in " +
        "the tool from which JSON is prepared for the property and uploaded in AWS S3 bucket.\n\n" +
        "If you are unable to resolve the issue then please contact developer.india@swmc.com.";
      let errorEmailSubject = JSON_PARSE_ERROR_EMAIL_SUBJECT_LINE;
      //replace listing id in the subject line
      errorEmailSubject = errorEmailSubject.replace(LISTING_ID_KWD, listingId);
      //build email JSON data
      const emailData = {
        toEmailAddress: process.env.REACT_APP_MARKETING_TRACKER_EMAIL,
        subject: errorEmailSubject,
        msgBody: errorEmailMsgBody,
      };

      axios
        .post(process.env.REACT_APP_PROPERTY_LISTINGS_EMAIL_API, emailData, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          // console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });

      return <ErrorPage />;
    }
  }
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const url =  "https://development.angelai.com/listings/" + listingId;
  const thumbnail = `https://d1mx30e6nw503j.cloudfront.net/images/open-house-listings/staging/${listingId}.jpg`;
 const offerPrice = currencyFormatter.format(
     parseInt(propertyData.offerPrice)
   );
 const propertyAddress = `${propertyData.streetAddress}, ${propertyData.city}, ${propertyData.state}, ${propertyData.zip}`;
 const title = `Your Perfect Home Awaits: ${
    propertyData.propertySold ? "Sold." : "For Sale."
   } ${propertyAddress}. Only ${offerPrice}. Unlock Seamless Financing with Sun West`;
   const originalDescription = `${propertyData.description}`;
   const maxCharacters = 160;

   let description = originalDescription;
   if (description.length > maxCharacters) {
     // Trim the description to the nearest complete word within the limit
     description = description.substring(0, maxCharacters);
     const lastSpaceIndex = description.lastIndexOf(" ");
     description = description.substring(0, lastSpaceIndex) + " ...";
   }

   // Trim any trailing whitespace
   description = description.trim();
   



  return (
    <div>
      
      {propertyData ?(
        <Helmet>
         <meta charset="utf-8"/>
 <link rel="canonical" href={url}/>
 <meta name="viewport" content="width=device-width, initial-scale=1"/>
 <title>{title}</title>
 <meta name="description" content={description} />
 <meta property="og:url" content={url} />
 <meta property="og:type" content="website" />
 <meta name="title" property="og:title" content={title} />
 <meta name="image" property="og:image" content={thumbnail} />
 <meta property="og:image:width" content="1200" />
 <meta property="og:image:height" content="600" />
 <meta name="description" property="og:description" content={description} />
 <meta name="twitter:card" content="summary" />
 <meta name="twitter:url" content={url} />
 <meta name="twitter:title" content={title} />
 <meta name="twitter:description" content={description} />
 <meta name="twitter:image" content={thumbnail}/>
        </Helmet>
      ):""}
      <Container>
        {loadingComplete ? (
          <div>
            <ScrollUpButton
              style={{
                width: 60,
                height: 60,
                bottom: "70px",
                borderRadius: "30px",
                background: "#0054a6",
              }}
              ContainerClassName="scrollToTop"
              TransitionClassName="overRideTransition"
            />
            <Suspense fallback={<div />}>
              <PropertyInfo
                propertyData={propertyData}
                listingDate={listingDate}
                loanOfficerData={loanOfficerData}
                loanOfficerIsActive={loanOfficerIsActive}
                propertySoldData={propertySoldData}
                listingId={originalListingId}
                webPageURL={propertyListingURL[0]}
                loanScenarioData={loanScenarioData}
                realtorData={realtorData}
                truApprovalData={truApprovalContactTeamInfo}
                loanOfficerActive={loanOfficerIsActive}
                loanOfficerDisclaimer={loanOfficerDisclaimer}
                realtorDisclaimer={realtorDisclaimer}
                disclaimerData={disclaimerData}
              />
            </Suspense>
            <Suspense fallback={<div />}>
              <LoanScenario
                loanScenarioData={loanScenarioData}
                buydown={buyDown}
                buyDownScenarioData={buyDownScenarioData}
              />
            </Suspense>
            <Suspense fallback={<div />}>
              <StaffInfo
                loanOfficerData={loanOfficerData}
                realtorData={realtorData}
                truApprovalData={truApprovalContactTeamInfo}
                loanOfficerActive={loanOfficerIsActive}
                propertyListingURL={propertyListingURL[0]}
              />
            </Suspense>
            <Suspense fallback={<div />}>
              <Disclaimer
                loanOfficerDisclaimer={loanOfficerDisclaimer}
                realtorDisclaimer={realtorDisclaimer}
                disclaimerData={disclaimerData}
              />
            </Suspense>
          </div>
        ) : (
          <Row className="p-2">
            <Col sm={12}>
              <SpinnerLoader />
            </Col>
          </Row>
        )}
      </Container>
      <LandingPageFooter />
    </div>
  );
}

export default ListingInfo;
