import React from "react";
import { Routes, Route } from "react-router-dom";
import ListingInfo from "../listing/ListingInfo";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import SearchIndex from "../search";

function ListingRoutes() {
  return (
    <Routes>
      <Route exact path="/:listingId" element={<ListingInfo />} />
      <Route exact path="/search" element={<SearchIndex />} />
    </Routes>
  );
}

export default ListingRoutes;
