import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

let host = window.location.host.toLowerCase();
const hostname = window.location.hostname.toLowerCase();
let pathname = window.location.pathname.toLowerCase();

if (pathname === "/listings") {
  pathname =  pathname + "/";
}
if (pathname === "/listings/") {
  let listingSearchURL;
  if (hostname === "localhost") {
    listingSearchURL = "http://" + host + pathname +"search";
  } else {
    listingSearchURL = "https://" + host + pathname +"search";
  }
  window.location.href = listingSearchURL;
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
