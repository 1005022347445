import React from "react";

import ListingRoutes from "./routes/ListingRoutes";

function App() {
  
  return <ListingRoutes />;
}

export default App;
