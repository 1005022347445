//constant variables
const ERROR_RESPONSE = 'ERROR';
const JSON_EXTENSTION = '.json';
const JPG_EXTENSTION = '.jpg';
const TEL_PREFIX = 'tel:+1-';
const MAIL_TO_PREFIX = 'mailto:';
const HTTPS_PREFIX = 'https://';
const PROPERTY_LISTING_IMG_CDN_URL = process.env.REACT_APP_CDN_BUCKET_URL + "/property-listings/img/";

//"mql" stands for "media query list".
const mqlMobile = window.matchMedia('(max-width: 600px)');
const mqlTablet = window.matchMedia('(max-width: 991px)');
const mqlLaptop = window.matchMedia('(max-width: 1024px)');
const mqlLaptopLarge = window.matchMedia('(max-width: 1440px)');
//check if form is loaded in mobile view or any other device
//if loaded on mobile returns true else false
const mobileView = mqlMobile.matches;
//if loaded on tablet returns true else false
const tabletView = mqlTablet.matches;
//if loaded on laptop returns true else false
const laptopView = mqlLaptop.matches;
//if loaded on laptop large returns true else false
const laptopLargeView = mqlLaptopLarge.matches;

/**
 * The below function is used to format amount in US currency format.
 */
const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
    //maximumFractionDigits: 0//These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
});

module.exports = {
    ERROR_RESPONSE,
    JSON_EXTENSTION,
    JPG_EXTENSTION,
    TEL_PREFIX,
    MAIL_TO_PREFIX,
    HTTPS_PREFIX,
    PROPERTY_LISTING_IMG_CDN_URL,
    mobileView,
    tabletView,
    laptopView,
    laptopLargeView,
    currencyFormatter
}
  